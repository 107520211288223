:root {
  --salmon-color: #FA8072;
}

.sign-up-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;

}

.sign-up-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-height: 60vh;
}

.sign-up-form h1 {
  margin-bottom: 20px;
}

.sign-up-form small {
  margin-bottom: 20px;
  text-align: center;
}

.sign-up-form .submit-button {
  background-color: var(--salmon-color);
  border-color: var(--salmon-color);
  text-align: center;
  display: flex;
  align-items: center;
}

.sign-up-form .submit-button:hover {
  background-color: #f67566;
  border-color: #f67566;
}

@media screen and (max-width: 768px) {
  .sign-up-form {
    padding: 20px;
  }
}