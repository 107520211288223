/* // Profile page header section. */
section.profile-header {
    background: #eee;
    padding: 5rem 0;
  
   
}

.blog_post_wrapper_container
{
    display: flex;
    flex-direction: column;
   
    align-items: center;

    margin-top: 40px;
    gap: 15px;
 
}

.sidebar_profile_page_your_favories
{
    font-size: 1em;
    margin-left: 40px;
}

.container_profile {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profile-image {
    border-radius: 50%;
    overflow: hidden;
    max-width: 250px;
    margin-right: 2.5rem;
}

.profile-link {
    margin-left: auto;
}


/* // Profile page badges section. */
section.profile-content {
    padding: 3rem 0;
}

.badges-block-intro {
    margin-bottom: 5rem;
}

.badges-block-list {
    display: flex;
    margin-bottom: 5rem;
}

.badge-element {
    display: flex;
    flex-direction: column;
    text-align: center;
    
    /* & + {
      margin-left: 1rem;
    } */
}

.profile_page_username
{
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 20px;
}

.profile_page_username_and_tag_wrapper
{
    display: flex;
    flex-direction: column;
}

.postProfile_wrapper_image
{
    display: flex;
    flex-direction: column;
    background-color: rgba(204, 204, 204, 0.541);
    align-items: center;
    padding: 10px;
    text-align: left;
}

.blog_post_header_your_posts
{
    padding: 20px;
    font-size: 2.5em;
    border-bottom: 5px solid lightgray;
}

.profile_page_post_left_sidebar
{
    display: flex;
    flex-direction: row;
}

.profile_page_wrapper_in_container
{
    display: flex;
    flex-direction: row;
}

.recipe_cards_posts_profile {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    
  }
  
  .recipe_card_post_profile {
    display: flex;
    flex-direction: column;
    width: 400px;
    flex-wrap: wrap;
    border-radius: 20px;
    margin: 25px;
    box-shadow: 8px 8px 8px lightgray;
    padding: 0px 15px 15px 15px;
  }

  .recipe_card_heading
  {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .recipe_card_post_each_h2
  {
    font-size: 15px;
  }
  
  /* Media queries for smaller screens */
  @media (max-width: 767px) {
    .recipe_cards_posts_profile {
        flex-direction: row;
      }

    .recipe_card_post_profile {
        width: 400px;
    }
  }
  
  @media (max-width: 480px) {
    .recipe_cards_posts_profile {
      grid-template-columns: 1fr;
    }
  }