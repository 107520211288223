.edit-account-page-container
{
  width: 70%;
  margin: 0 auto;
  background-color: white;
  padding: 20px;
}

.edit-count-page-divider
{
    display: flex;
    flex-direction: row;
}

.edit-account-page-left-side
{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 20px;
}

.edit-account-page-right-side
{
    display: flex;
    flex-direction: column;
    width: 100%;
 
}
.edit-account-page-right-side-account-information-password
{
    display: flex;
    flex-direction: column;
    gap: 10px;

}

.gerdTag-containe {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .gerdTag-arrow {
    
    align-self: flex-start;
  }

  .gerdTag-arrowIcon
  {
    cursor: pointer;
  }
  
  .gerdTag-select{
    text-align: center;
  }
  
  .gerdTag-save {
    margin-top: 10px;
  
    text-align: center;
  }