.logout-page-container {
    background-color: #f0f2f5;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .logout-card {
    width: 400px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .logout-title {
    color: #2a2e35;
    margin-bottom: 24px;
  }
  
  .logout-message {
    color: #6e7681;
    margin-bottom: 32px;
  }
  
  .logout-button {
    background-color: #1890ff;
    border-color: #1890ff;
    margin-top: 16px;
  }
  
  .logout-button:hover,
  .logout-button:focus {
    background-color: #096dd9;
    border-color: #096dd9;
  }