.NavbarItemsLogin {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    height: 80px;
    background-color: #fff;
    box-shadow: 0 8px 6px -6px #999;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  
  .menu-section-login {
    display: flex;
    align-items: center;
  }
  
  .menu-icon-login {
    
    margin-right: 30px;
  }
  
  .links-login a {
    margin-right: 30px;
    text-decoration: none;
    color: black;
  }
  
  .right_links-login {
    display: flex;
    align-items: center;
  }
  
  .signup-option-login p {
    cursor: pointer;
    text-decoration: underline;
    color: black;
  }
  
  /* Media Queries */
  @media screen and (max-width: 768px) {
    .menu-icon-login {
      display: none;
    }
   
  }