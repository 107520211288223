.deactivate-account-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .deactivate-account-btn {
    background-color: #f44336;
    border: none;
    color: #fff;
    padding: 12px 24px;
    text-align: center;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    margin: 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  .deactivate-account-btn:hover {
    background-color: #ff5c5c;
  }
  
  .steps-container {
    margin-top: 30px;
  }
  
  .steps-content {
    margin-top: 20px;
    font-size: 18px;
    text-align: center;
  }
  