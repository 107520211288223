
  
  /* Home Container Styles */
  .home-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    
    flex-direction: column;
  }

  /* Trending Gerd Posts Headline Styles Icon */
  .trending_gerd_friendly_post_headline_icon
  {
    cursor: pointer;
  }
  
  /* Trending Gerd Posts Headline Styles */
  .trending_gerd_friendly_post_headline_h3
  {
    font-size: 1.7rem;
    cursor: pointer;
  
  }

  /* Trending Gerd Posts Headline Styles Underline */
  .trending_gerd_friendly_post_headline_h3:hover 
  {
    text-decoration: underline;
    text-decoration-color: darkred;
  }
  /* Trending Gerd Posts Container Styles */
  .trending_gerd_posts_container {
    width: 100%;
    max-width: 1500px;
    gap: 10px;
    /* padding: 1rem; */
    border-radius: 10px;
    
  }
  
  /* Trending Gerd Friendly Post Headline Styles */
  .trending_gerd_friendly_post_headline {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    justify-content: center;
  }
  
  /* Trending Posts Styles */
  .trending_posts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;

  }
  
  /* Trending Post Styles */
  .trending_post {
    width: 100%;
    max-width: 300px;
    background-color: #fff;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #999;
  }
  
  /* New Posts Container Styles */
  .new_posts_container {
    width: 100%;
    max-width: 1500px;
    background-color: #f2f2f2;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #999;
    margin-top: 2rem;
  }
  
  /* New Posts Headline Styles */
  .new_posts_headline {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: center;
  }
  
  /* New Posts Styles */
  .new_posts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  /* New Post Styles */
  .new_post {
    width: 100%;
    max-width: 300px;
    background-color: #fff;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #999;
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    .home-container {
      flex-wrap: wrap;
      justify-content: center;
    }
      .trending_gerd_posts_container, .new_posts_container 
        {
        width: 90%;
        }
        .trending_post, .new_post 
        {
        width: 45%;
        margin-bottom: 2rem;
        }
        .trending_gerd_friendly_post_headline_h3
        {
          font-size: 1.2rem;
        }
}
    