
.searchBlock_content{
    display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
}

.searchBlock_form{
   
    height: auto;
    width: 100%;
   display: grid;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.keyword_field, .recipe_ingred_field{
    letter-spacing: 0;
    border: solid 3px rgba(0, 0, 0, 0.39);
    color: rgb(0, 0, 0);
    margin-bottom: 30px;
    height: 55px;
    padding-left: 24px;
    width: 358px;
}

.searchButton{
    background: #f5e50d;
    width: 50px;
    height: 33px;
    cursor: pointer;
}

.recipe_name
{
    font-size: 20px;
    font-weight: 600;
    color: #000;
    margin-bottom: 10px;
}

.keyword_box {
    margin-bottom: 10px;
  }

  .searchBlock_form__button {
    display:flex;
    justify-content:center;
}