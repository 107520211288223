.NavbarItems-update {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    height: 80px;
    background-color: #fff;
    box-shadow: 0 8px 6px -6px #999;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  
  .menu-section-update {
    display: flex;
    align-items: center;
  }
  
  .menu-icon-update {
    
    margin-right: 30px;
  }
  
  .links-update a {
    margin-right: 30px;
    text-decoration: none;
    color: black;
  }
  
  .right_links-update {
    display: flex;
    align-items: center;
    gap: 40px;
  }
  
  .patreon-link-update a {
    text-decoration: none;
    color: black;
  }
  
 
  /* Media Queries */
  @media screen and (max-width: 768px) {
    .menu-icon-update {
      display: none;
    }


  }