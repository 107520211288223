/* Center the dialog card in the middle of the page */
.d_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 1rem;

    background-color: #f4f4f4;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Style the title of the dialog card */
  .d_card h2 {
    font-size: 0.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .dialog_card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .dialog_card h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .dialog_card_description_limited {
    margin-bottom: 10px;
  }
  
  .dialog_card_description_limited p {
    font-size: 15px;
    margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: break-word;
  }
  
  .dialog_readmore {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: salmon;
    color: white;
    border-radius: 5px;
    padding: 5px;
  }
  
  .dialog_readmore span {
      margin-right:5px;
  }

  @media only screen and (max-width:768px) {
    .dialog_card h2 {
        font-size:20px;
    }
    .dialog_card_description_limited p {
        font-size:14px;
    }
}

.dialog_card_sidebar_ad
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 1rem;

    background-color: #f4f4f4;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}