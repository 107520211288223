.container_cards{
    display: flex;
    margin: 4px;
}

.dialog_card {
    margin: 20px;
    padding: 20px;
    height: 400px;
    width: 300px;
    border: solid 1px #ebebeb;
    border-style: solid;
    border-color: rgb(146, 146, 146);
    background-color: rgb(238, 238, 238);
}

.dialog_text{
    font-size: 5em;
    color: black;
    text-decoration: none;
}

.dialog_text:hover{
    text-decoration: underline;
}

.dialog_readmore{
    color: black;
    cursor: pointer;
    margin: 20px;
    
}


.dialog_readmore span{
 color: black;
 cursor: pointer;
 text-decoration: underline;
}

.container_dialog_title{
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(0,0,0,.05);
}

.container_dialog_title > div{
    font-size: 15px;
    right: 32px;
}

.container_header_auth_section{
    display: flex;
    width: 90%;
    font-size: 3em;
    justify-content: end;
    height: 55px;
    box-sizing: border-box;
    right: 32px;
    align-items: center;

}
.container_header_auth_socialicons{
    padding: 6px 55px 0;
    display: flex;
    
}

.container_header_auth_socialicons > div{
   padding-left: 30px;
}

.container_header_auth_login{
    padding: 0 12px;
    border-left: 1px solid rgba(0,0,0,.15);
}

.container_header_auth_signup{
    padding: 0 12px;
    border-left: 1px solid rgba(0,0,0,.15);
}

.top_menu{
    overflow-y: scroll;
    overflow-x: hidden;
    
    width: 325px;
    height: 450px;
    padding: 32px 0;
}

.pop-up-nav-menu{

  list-style: none;
 padding: 0;

}


.nav__link{
display: block;
text-decoration: none;
font-weight: bold;
background-color: white;
padding: 1rem;
width: 18vw;
color: black;

}
.nav__link:hover{ 
    background-color: lightgray;
}

.arrow__right{
    display: inline-block;
    align-items: center;
    padding-left: 5px;
    top: 7px;
   
    position: relative;
}

.search__icon{
    display: inline-block;
    align-items: center;
    padding-left: 1px;
    top: 7px;
    left: 80px;
    position: relative;
}

.dialog_page_title{
    display: flex;
    width: 450px;
    padding-left: 10px;
    justify-content: space-between;
    height: 45px;
    box-sizing: border-box;
    border-bottom:  2px solid rgba(0, 0, 0, 0.137);
    
}
.dialog_page_title_link{
    text-decoration: none;
    color: black;
    font-weight: bold;
}

.dialog_card_submenu_list 
{
    column-count: 3;
    list-style-type: none;
    gap: 15px;
    margin: 20px;
    margin-right: 10px;
    display: inline;

}

.dialog_card_submenu_list li{
 padding: 15px;
 margin: 20px;
 display: inline-block;
 cursor: pointer;
}

.pop_up_nav_menu_items_list
{
    /*Align arrow and text to center  */
    display: flex;

    flex: row;

    /*Align arrow and text to center  */
    cursor: pointer;
}
.dialog_page_wrapper{
    display: block;
    width: 60%;
}

.dialog_card_submenu_body{
    display: flex;
}

.dialog_card_sidebar{
position: relative;
padding: 20px;
width: 190px;
border: 4px solid rgba(173, 173, 173, 0.39);
}

.container_header_auth_logout
{
    cursor: pointer;
}

.container_header_auth_login
{
    cursor: pointer;
  
}

.container_header_auth_signup
{
    cursor: pointer;
}

