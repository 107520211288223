
.post_textarea_dialog_box_raterecipe_input:focus{
    outline: none;
}

.post_textarea_dialog_box_raterecipe_input{
    height: 180px;
    width: 100%; 
    margin: 0px; 
    border: white;
    border-radius: 5px;
    background: white;
    border: 0.5px solid lightgray;
}

.recipe_title_name_dialog_rate_recipe
{
    align-items: center;
    text-align: center;
    padding: 20px;
}