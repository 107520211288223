.blog_post_wrapper_container{
    
    height: auto;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
}
.recipe_cards_posts
{
 /* display: flex;
 flex-wrap: wrap;
  */
  display: flex;

  flex-wrap: wrap;
  align-items: center;
}

.recipe_card_post
{
    display: flex;
    flex-direction: column;
    width: 400px;
    flex-wrap: wrap;
    border-radius: 20px;
    margin: 25px;
    box-shadow: 8px 8px 8px lightgray;
    padding: 0px 15px 15px 15px;
  
    
}

.recipe_card_post_each
{
    display: flex;
    border: 0.1px solid black;
    flex-direction: column;
    justify-content: space-between;
   
}

.recipe_card_post_underheaderinformation
{
    display: flex;
    justify-content: space-between;
    margin: 10px;
    
}

.recipe_card_post_each_h2
{
    width: 100%;
}

.recipe_card_post_each_h2 h2
{
    font-size: 15px;
}

.recipe_card_post_description_p
{
    width: 80%;
    line-height: 20px;
    flex-wrap: wrap;
    word-wrap: break-word;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
}

.recipe_card_heading
{
    margin-top: 10px;
    margin-bottom: 10px;
}

.recipe_card_post_category
{
    align-items: center;
    text-align: center;
    width: 30%;
    background-color: #E1F0C4;
    margin-left: 10px;
    border-radius: 11px;
    margin: 10px;
    word-wrap: break-word;
    text-decoration: none;
    color: black;
}

.recipe_card_post_emailSlicedName 
{
text-decoration: underline;
text-decoration-color: salmon;
margin-top: 15px;
}

.recipe_card_food_descriptors
{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5em;
}

.recipe_card_food_descriptor
{
    background-color: #FFE2D1;
    border-radius: 12px;
    margin-left: 11px;
    padding: 5px;
   
}

.recipe_card_post_underheaderinformation_calories
{
    color:rgba(161, 161, 161, 0.815)
}

.recipe_cards_posts_load_more_button {
    margin-top: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

 /* Mobile styles */
@media only screen and (max-width: 767px) {
    .recipe_card_post {
        width: 100%;
        margin: 15px 0;
        padding: 6px;
     
    }
    .recipe_card_post_description_p {
        width: 60%;
        align-items: center;
    }
    .recipe_card_post_each_h2
    {
        font-size: 1.2em;
    }
    .recipe_card_post_category {
        width: 35%;
        text-align: left;
        margin-left: 0;
        padding: 10px;
    }
    .recipe_card_post_underheaderinformation
    {
        
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin: 2px;
        justify-content: flex-start;
    }
}

/* Tablet styles */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .recipe_card_post {
        width: calc(50% - 50px);
        margin: 15px;
    }
    .recipe_card_post_description_p {
        width: 60%;
    }
    .recipe_card_post_category {
        width: 40%;
        text-align: left;
        margin-left: 0;
    }
    .recipe_card_post_ratings
    {
        margin: 0px;
    }
    .recipe_card_post_underheaderinformation
    {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin: 2px;
        justify-content: flex-start;
    }
}
  
  