.NavbarItems {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 80px;
  position: sticky;
  top: 0;
  background-color: #fff;
  box-shadow: 0 8px 6px -6px #999;
  z-index: 1000;
}

.menu-logo {
  display: flex;
  align-items: center;
  gap: 40px;
}

.menu-icon {
  display: block;
}

.logo {
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  color: #4a4a4a;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.logo-mobile {
  display: none;
}

.nav-menu {
  list-style: none;
  display: flex;
}

.nav-item {
  margin-left: 20px;
}

.signup_button,
.login_button {
  font-size: 20px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .menu-icon {
    display: none;
  }

  .logo-desktop {
    display: none;
  }

  .logo-mobile {
    display: block;
  }

  

  .signup_button,
  .login_button {
    font-size: 15px;
    padding: 10px 15px;
  }
}