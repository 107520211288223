.NavbarItemsSignUp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    height: 80px;
    background-color: #fff;
    box-shadow: 0 8px 6px -6px #999;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  
  .menu-section-signup {
    display: flex;
    align-items: center;
  }
  
  .menu-icon-signup {
    display: none;
    margin-right: 30px;
  }
  
  .links-signup a {
    margin-right: 30px;
    text-decoration: none;
    color: black;
  }
  
  .right_links-signup {
    display: flex;
    align-items: center;
    gap: 40px;
  }
  
  .login-option-signup p, .patreon-link-signup a {
    cursor: pointer;
    text-decoration: underline;
    color: black;
  }
  
  /* Media Queries */
  @media screen and (max-width: 768px) {
    .menu-icon-signup {
      display: block;
    }

  }