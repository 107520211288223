.footer {
    background-color: #44795D;
    color: #fff;
    text-align: center;
    padding: 40px 20px;
  }
  
  .footer-top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer-logo {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .footer-logo h2 {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
  }
  
  .footer-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .footer-column {
    display: flex;
    flex-direction: column;
    margin: 0 20px;
    flex-grow: 1;
    flex-shrink: 0;
  }
  
  .footer-column h3 {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .footer-column a {
    margin-bottom: 10px;
    color: #fff;
    font-size: 14px;
  }
  
  .footer-column a:hover {
    color: #1890ff;
  }
  
  .footer-bottom {
    margin-top: 20px;
  }
  
  .footer-bottom p {
    color: #fff;
    font-size: 14px;
  }
  
  @media (min-width: 576px) {
    .footer-top {
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
    }
  
    .footer-logo {
      flex-basis: auto;
      text-align: left;
      margin-bottom: 0;
    }
  
    .footer-links {
      flex-basis: 50%;
    }
  
    .footer-bottom {
      margin-top: 0;
    }
  }