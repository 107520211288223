.single_post_container {
    display: flex;
    width: 100%;
    margin: 0 auto;
    padding: 0 1rem; /* Add some horizontal padding for spacing on all screens */
}

.single_post_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    margin: 0 auto;
}

.single_post_container_rating {
    display: flex;
    gap: 15px;
    flex-direction: row;
    margin-bottom: 10px;
    width: 100%;
    justify-content: center;
}

.single_post_recipe_desc {
    font-size: 1rem;
    text-align: center;
    width: 100%;
    max-width: 800px;
    margin: 1rem 0;
}

.single_post_breadcrumbs {
    float: left;
    margin: 32px 0 0 0;
}

.single_post_recipe_ingredients_list {
    padding-top: 20px;
    border-top: 3px solid lightgray;
    gap: 30px;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
    padding-bottom: 30px;
    border-bottom: 3px solid lightgray;
}

.single_post_recipe_linkToProfile {
    text-decoration: underline;
    text-decoration-color: red;
    color: black;
}

.single_post_recipe_poster_information {
    display: row;
    gap: 65px;
    justify-content: space-between;
    text-align: center;
    margin: 15px auto;
    width: 100%;
    max-width: 800px;
}

.single_post_bookmark {
    display: flex;
    justify-content: center;
    gap: 65px;
    width: 100%;
    max-width: 800px;
    margin: 15px auto;
}

.post_headerh1 {
    font-size: 3rem;
    text-align: left;
    width: 100%;
    max-width: 800px;
    margin: 1rem 0;
}

.single_post_recipe_stepsForRecipe_instructions {
    gap: 30px;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    margin-bottom: 5px;
    padding-bottom: 30px;
    border-bottom: 3px solid lightgray;
}

.single_post_recipe_stepsForRecipe_container_instructions {
    display: flex;
    width: 100%;
    max-width: 800px;
    flex-direction: column;
}

.single_post_recipe_containter_ingredients_list {
    display: flex;
    width: 100%;
    max-width: 800px;
    flex-direction: column;
}

.single_post_recipe_ingredient_count {
    margin: 22px auto 0;
    width: 95%;
}

.single_post_recipe_steps {
    display: flex;
    gap: 10px;
    margin: 22px auto 0;
    flex-direction: row;
    width: 95%;
}

.single_post_containter {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.single_post_recipe_customer_feedback_banner {
    display: flex;
    flex-direction: row;
    gap: 35px;
    align-items: center;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.single_post_recipe_customer_paragraph_block {
    width: 100%;
    max-width: 800px;
    line-height: 21px;
}

.single_post_recipe_customer_feedback {
    vertical-align: middle;
    margin: 32px auto 0;
    width: 100%;
}

.single_post_recipe_utilities {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin: 5px auto 0;
    width: 100%;
    max-width: 800px;
    justify-content: center;
    flex-wrap: wrap;
}

.single_post_recipe_utilities_shareIcon,
.single_post_recipe_utilities_downloadIcon,
.single_post_recipe_utilities_twitterIcon,
.single_post_recipe_utilities_facebookIcon,
.single_post_recipe_utilities_pintrestIcon {
    cursor: pointer;
}

.single_post_recipe_sidebar_ingredients_list {
    width: 300px;
    float: left;
}

.single_post_recipe_comments_allowed {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin: 5px auto 0;
    width: 100%;
    max-width: 800px;
}

/* Mobile Styles */
@media only screen and (max-width: 600px) {
    body {
        overflow-x: hidden;
    }

    .single_post_container {
        width: 100%;
        margin: 0;
        padding: 0.5rem;
    }

    .post_headerh1 {
        font-size: 2rem;
        text-align: center;
    }

    .single_post_recipe_desc,
    .single_post_recipe_poster_information,
    .single_post_bookmark,
    .single_post_recipe_stepsForRecipe_container_instructions,
    .single_post_recipe_containter_ingredients_list,
    .single_post_recipe_utilities,
    .single_post_recipe_customer_paragraph_block {
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
    }

    .single_post_container_rating {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .single_post_recipe_ingredients_list,
    .single_post_recipe_stepsForRecipe_instructions {
        flex-direction: column;
        align-items: center;
    }

    .single_post_recipe_poster_information,
    .single_post_bookmark {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .single_post_recipe_sidebar_ingredients_list {
        width: 100%;
        float: none;
    }

    .single_post_recipe_comments_allowed {
        flex-direction: column;
        align-items: center;
    }

    .single_post_recipe_utilities {
        justify-content: center;
    }
}
