.contact-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 40px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .contact-form h2 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
  }
  
  .contact-form form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-form label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
    color: #000;
  }
  
  .contact-form input,
  .contact-form textarea {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    font-size: 14px;
    color: #000;
  }
  
  .contact-form textarea {
    resize: vertical;
    min-height: 120px;
  }
  
  .contact-form button[type="submit"] {
    display: block;
    margin: 0 auto;
    width: 100%;
    background-color: #1890ff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
   
    margin-top: 20px;
    cursor: pointer;
    text-align: center;
    align-items: center;
    transition: background-color 0.2s ease-in-out;
  }
  
  .contact-form button[type="submit"]:hover {
    background-color: #40a9ff;
  }
  
  @media (max-width: 768px) {
    .contact-form {
      padding: 20px;
    }
  }