
.forgot-password-form-container
{
    margin: 2rem auto;
    padding: 1rem;
    max-width: 500px;
    background-color: hsla(0, 0%, 93%, 0.123);
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.267);
}

.forgot-password-form
{
    width: 100%;
    padding: 2rem;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}

.check-your-email-form-div
{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
}

  /* Form fields */
  .forgot-password-form input,
  .forgot-password-form button,
  .forgot-password-form label {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 0.1rem;

    border-radius: 5px;
    font-size: 1rem;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  }

  @media only screen and (max-width: 600px) {
    /* Make form inputs and labels smaller on small screens */
    .forgot-password-form, .sign-up-form label {
        font-size: 0.8rem;
    }
  
    /* Center the form on small screens */
    .forgot-password-form {
        text-align: center;
    }
  }