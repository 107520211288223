/* Main container */
.sign-in-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 1rem;
}

/* Sign-in form */
.sign-in-form {
  width: 100%;
  max-width: 400px;
  padding: 2rem;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Sign-in form title */
.sign-in-form h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #333;
}

/* Form fields */
.sign-in-form input,
.sign-in-form button,
.sign-in-form label {
  width: 100%;
}

/* Form labels */
.sign-in-form label {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

/* Form inputs */
.sign-in-form input {
  font-size: 1rem;
  padding: 0.5rem;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

.sign-in-form input:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 1px #007bff;
}

/* Submit button */
.submit-button {
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  border-radius: 5px;
  border: none;
  font-size: 1rem;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

/* Submit button hover state */
.submit-button:hover {
  background-color: #0056b3;
}

/* Remember me checkbox */
.sign-in-remember-me {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/* Sign-in footer */
.sign-in-footer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: left;
}

.sign-in-footer a {
  font-size: 1rem;
  color: #007bff;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar {
  margin: 1rem;
}

/* Forgot password and sign-up link hover state */
.sign-in-footer a:hover {
  color: #0056b3;
}

.redirected-alert
{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  width: 20%;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  padding: 12px 20px;
  font-size: 16px;
  
}
@media only screen and (max-width: 480px) {
  .sign-in-form {
    padding: 1rem;
  }
}