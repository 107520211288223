.dropdown-btn {
    border: none;
    color: #4d4d4d;
    font-size: 16px;
    cursor: pointer;
    background: none;
    outline: none;
    transition: color 0.3s;
  }
  
  .dropdown-btn:hover {
    color: #1890ff;
  }
  
  .dropdown-btn:focus {
    box-shadow: none;
  }